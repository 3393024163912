import { allMoviesList, selectedMovies } from './store'
import { get_store_value as getStoreValue } from 'svelte/internal'

// movie list from api
export async function getAndSetMovieList () {
  const res = await fetch('https://movies.mihir.ch/api/list')
  const list = await res.json()
  allMoviesList.set(list)
}

// return placeholder if no poster_path
export function getPosterPath (movie, altPath) {
  const poster = altPath ? movie.info.poster : movie.poster_path
  if (poster) {
    return 'https://image.tmdb.org/t/p/w500' + poster
  }

  return '/img/poster_placeholder.svg'
}

// get a truncated value of movie.overview for preview
export function getTruncatedOverview (movie) {
  if (movie.overview.length < 120) {
    return movie.overview
  }

  return movie.overview.substring(0, 100).trim() + '...'
}

// get the full language name
export function getLanguage (code) {
  const langMap = {
    aa: 'Afar',
    ab: 'Abkhazian',
    af: 'Afrikaans',
    ak: 'Akan',
    am: 'Amharic',
    an: 'Aragonese',
    ar: 'Arabic',
    as: 'Assamese',
    av: 'Avar',
    ay: 'Aymara',
    az: 'Azerbaijani',
    ba: 'Bashkir',
    be: 'Belarusian',
    bg: 'Bulgarian',
    bh: 'Bihari',
    bi: 'Bislama',
    bm: 'Bambara',
    bn: 'Bengali',
    bo: 'Tibetan',
    br: 'Breton',
    bs: 'Bosnian',
    ca: 'Catalan',
    ce: 'Chechen',
    ch: 'Chamorro',
    co: 'Corsican',
    cr: 'Cree',
    cs: 'Czech',
    cu: 'Old Church Slavonic / Old Bulgarian',
    cv: 'Chuvash',
    cy: 'Welsh',
    da: 'Danish',
    de: 'German',
    dv: 'Divehi',
    dz: 'Dzongkha',
    ee: 'Ewe',
    el: 'Greek',
    en: 'English',
    eo: 'Esperanto',
    es: 'Spanish',
    et: 'Estonian',
    eu: 'Basque',
    fa: 'Persian',
    ff: 'Peul',
    fi: 'Finnish',
    fj: 'Fijian',
    fo: 'Faroese',
    fr: 'French',
    fy: 'West Frisian',
    ga: 'Irish',
    gd: 'Scottish Gaelic',
    gl: 'Galician',
    gn: 'Guarani',
    gu: 'Gujarati',
    gv: 'Manx',
    ha: 'Hausa',
    he: 'Hebrew',
    hi: 'Hindi',
    ho: 'Hiri Motu',
    hr: 'Croatian',
    ht: 'Haitian',
    hu: 'Hungarian',
    hy: 'Armenian',
    hz: 'Herero',
    ia: 'Interlingua',
    id: 'Indonesian',
    ie: 'Interlingue',
    ig: 'Igbo',
    ii: 'Sichuan Yi',
    ik: 'Inupiak',
    io: 'Ido',
    is: 'Icelandic',
    it: 'Italian',
    iu: 'Inuktitut',
    ja: 'Japanese',
    jv: 'Javanese',
    ka: 'Georgian',
    kg: 'Kongo',
    ki: 'Kikuyu',
    kj: 'Kuanyama',
    kk: 'Kazakh',
    kl: 'Greenlandic',
    km: 'Cambodian',
    kn: 'Kannada',
    ko: 'Korean',
    kr: 'Kanuri',
    ks: 'Kashmiri',
    ku: 'Kurdish',
    kv: 'Komi',
    kw: 'Cornish',
    ky: 'Kirghiz',
    la: 'Latin',
    lb: 'Luxembourgish',
    lg: 'Ganda',
    li: 'Limburgian',
    ln: 'Lingala',
    lo: 'Laotian',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    lv: 'Latvian',
    mg: 'Malagasy',
    mh: 'Marshallese',
    mi: 'Maori',
    mk: 'Macedonian',
    ml: 'Malayalam',
    mn: 'Mongolian',
    mo: 'Moldovan',
    mr: 'Marathi',
    ms: 'Malay',
    mt: 'Maltese',
    my: 'Burmese',
    na: 'Nauruan',
    nb: 'Norwegian Bokmål',
    nd: 'North Ndebele',
    ne: 'Nepali',
    ng: 'Ndonga',
    nl: 'Dutch',
    nn: 'Norwegian Nynorsk',
    no: 'Norwegian',
    nr: 'South Ndebele',
    nv: 'Navajo',
    ny: 'Chichewa',
    oc: 'Occitan',
    oj: 'Ojibwa',
    om: 'Oromo',
    or: 'Oriya',
    os: 'Ossetian / Ossetic',
    pa: 'Panjabi / Punjabi',
    pi: 'Pali',
    pl: 'Polish',
    ps: 'Pashto',
    pt: 'Portuguese',
    qu: 'Quechua',
    rm: 'Raeto Romance',
    rn: 'Kirundi',
    ro: 'Romanian',
    ru: 'Russian',
    rw: 'Rwandi',
    sa: 'Sanskrit',
    sc: 'Sardinian',
    sd: 'Sindhi',
    se: 'Northern Sami',
    sg: 'Sango',
    sh: 'Serbo-Croatian',
    si: 'Sinhalese',
    sk: 'Slovak',
    sl: 'Slovenian',
    sm: 'Samoan',
    sn: 'Shona',
    so: 'Somalia',
    sq: 'Albanian',
    sr: 'Serbian',
    ss: 'Swati',
    st: 'Southern Sotho',
    su: 'Sundanese',
    sv: 'Swedish',
    sw: 'Swahili',
    ta: 'Tamil',
    te: 'Telugu',
    tg: 'Tajik',
    th: 'Thai',
    ti: 'Tigrinya',
    tk: 'Turkmen',
    tl: 'Tagalog / Filipino',
    tn: 'Tswana',
    to: 'Tonga',
    tr: 'Turkish',
    ts: 'Tsonga',
    tt: 'Tatar',
    tw: 'Twi',
    ty: 'Tahitian',
    ug: 'Uyghur',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    ve: 'Venda',
    vi: 'Vietnamese',
    vo: 'Volapük',
    wa: 'Walloon',
    wo: 'Wolof',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang',
    zh: 'Chinese',
    zu: 'Zulu'
  }
  return langMap[code]
}

// runtime formatter
export function runtimeFormat (runtime) {
  const hours = Math.floor(runtime / 60)
  const minutes = Math.floor(runtime - hours * 60)
  return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`
}

export function setValueToPath (obj, path, val) {
  const keys = path.split('.')
  const lastKey = keys.pop()
  const lastObj = keys.reduce(
    // eslint-disable-next-line no-return-assign
    (obj, key) => obj[key] = obj[key] || {},
    obj
  )
  lastObj[lastKey] = val
}

// Get value using path
export const getValueFromPath = (obj, path) => path.split('.').reduce((a, v) => a[v], obj)

// to title case
export const titler = value => {
  const words = value.split('-')
  words[0] = words[0][0].toUpperCase() + words[0].slice(1)
  return words.join(' ')
}

export const closeForm = target => {
  if (!target) {
    selectedMovies.set({
      add: undefined,
      edit: undefined
    })
  } else {
    selectedMovies.set({
      ...getStoreValue(selectedMovies),
      [target]: undefined
    })
  }
}

export const sleep = async duration => new Promise(resolve => setTimeout(resolve, duration))

export const settingsOptions = {
  toWatch: {
    sort: [
      'alphabetical',
      'date-added',
      'runtime',
      'added-by',
      'IMDb'
    ],
    group: {
      language: 'info.language',
      adder: 'adder',
      none: 'none'
    }
  },
  watched: {
    sort: [
      'alphabetical',
      'date-added',
      'date-watched',
      'runtime',
      'ratings',
      'added-by',
      'medium',
      'when',
      'IMDb'
    ],
    group: {
      language: 'info.language',
      adder: 'adder',
      medium: 'watched.medium',
      when: 'watched.when',
      none: 'none'
    }
  }
}

export const sorts = {
  'date-added': (a, b) => a.added > b.added ? 1 : -1,
  'date-watched': (a, b) => a.watched.date > b.watched.date ? 1 : -1,
  alphabetical: (a, b) => a.title > b.title ? 1 : -1,
  runtime: (a, b) => a.info.runtime > b.info.runtime ? 1 : -1,
  'added-by': (a, b) => a.adder > b.adder ? 1 : -1,
  when: (a, b) => a.watched.when > b.watched.when ? 1 : -1,
  medium: (a, b) => a.watched.medium > b.watched.medium ? 1 : -1,
  IMDb: (a, b) => a.info.imdb.rating < b.info.imdb.rating ? 1 : -1,
  language: (a, b) => a.info.language > b.info.language ? 1 : -1,
  ratings: (a, b) =>
    (a.watched.ratings.ananya + a.watched.ratings.mihir) <
    (b.watched.ratings.ananya + b.watched.ratings.mihir) ? 1 : -1
}
