<script>
  import { fade } from 'svelte/transition'
  import { nextMovie, selectedMovies } from './store'
  import { getPosterPath, runtimeFormat } from './utils'

  let collapseNext = false
  let expandOverview = false

  $: movie = $nextMovie

  const markAsWatched = () => {
    const selectedMovie = {
      ...movie,
      watched: {
        ...movie.watched,
        isWatched: true
      }
    }
    $selectedMovies.edit = selectedMovie
  }
</script>

<section id='next-movie'>
  <div class='title'>
    <h2>Next Up</h2>
    <button
      class='collapser'
      on:click={() => collapseNext = !collapseNext}
    >[{collapseNext ? '+' : '-'}]</button>
  </div>
  {#if !collapseNext}
    {#if movie}
      <div class='container' transition:fade={{ duration: 200 }}>
        <img src={getPosterPath(movie, true)} alt='{movie.title} poster'>
        <div class='info-container'>
          <h3>{movie.title}</h3>
          <p class='sub'>
            {movie.info.language}
            &middot;
            {runtimeFormat(movie.info.runtime)}
            &middot;
            {movie.adder}
          </p>
          {#if movie.info.overview.length < 140}
            <p class='overview'>{movie.info.overview}</p>
          {:else}
            <p class='overview' on:click={() => expandOverview = true}>{
              expandOverview ? movie.info.overview : movie.info.overview.substr(0, 120) + '... [+]'
            }</p>
          {/if}
          <div class='btn-container'>
            <button on:click={markAsWatched}>mark as watched</button>
          </div>
        </div>
      </div>
    {:else}
      <p class='error' transition:fade={{ duration: 200 }}>Next movie not specified :/</p>
    {/if}
  {/if}
</section>

<style>
  section {
    width: 90%;
    margin: 40px auto;
    border-radius: 5px;
    background: #1e1e30;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.12);
  }

  .error {
    margin-top: 20px;
    font-family: IBM Plex Sans;
    text-align: center;
  }

  .container {
    margin-top: 30px;
    display: flex;
  }

  .info-container {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .title {
    display: flex;
    border-bottom: 1px solid #bdbdbd11;
    padding-bottom: 10px;
  }

  h2 {
    font-family: Bebas Neue;
    flex: 1;
  }

  .collapser {
    font-family: monospace;
    text-decoration: none;
    margin-left: 10px;
    font-size: 16px;
    align-self: center;
    color: #bdbdbd4d;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  img {
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4)
  }

  h3 {
    font-family: Bebas Neue;
    font-size: 36px;
  }

  .sub {
    margin: 10px 0;
    font-family: IBM Plex Sans;
  }

  .overview {
    font-family: IBM Plex Sans;
    color: #6a6a73;
  }

  .btn-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .btn-container button {
    background: #30583d;
    font-size: 18px;
    font-weight: 700;
    font-family: Bebas Neue;
    padding: 5px 10px;
    cursor: pointer;
    align-self: flex-end;
    border: none;
    color: #bdbdbd;
    transition: 0.1s all linear;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    outline: none;
  }

  .btn-container button:active,
  .btn-container button:hover {
    background: #30583d54;
    box-shadow: 0 10px 20px rgba(0,0,0,0.4);
  }
</style>
