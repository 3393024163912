import fuzzy from 'fuzzy'
import { writable, derived } from 'svelte/store'
import { sorts } from './utils'

export const allMoviesList = writable(JSON.parse(localStorage.getItem('movies')) || [])

allMoviesList.subscribe(val => localStorage.setItem('movies', JSON.stringify(val)))

export const settings = writable(JSON.parse(localStorage.getItem('settings')) || {
  fuzzyFilter: '',
  collapseMonths: true,
  toWatch: {
    view: 'table',
    group: 'language',
    sort: 'date-added',
    posters: true,
    reverse: false,
    collapse: false
  },
  watched: {
    view: 'table',
    group: 'language',
    sort: 'date-watched',
    posters: true,
    reverse: false,
    collapse: false
  }
})

settings.subscribe(val => localStorage.setItem('settings', JSON.stringify(val)))

const createDerivedList = listType => derived(
  [allMoviesList, settings],
  ([$allMoviesList, $settings]) => {
    const list = $allMoviesList
      .filter(movie =>
        listType === 'watched'
          ? movie.watched.isWatched
          : !movie.watched.isWatched
      )
      .filter(movie => fuzzy.test($settings.fuzzyFilter || '', movie.title))
      .sort(sorts[$settings[listType].sort])
    return $settings[listType].reverse ? list.reverse() : list
  }
)

export const toWatchList = createDerivedList('toWatch')

export const watchedList = createDerivedList('watched')

export const selectedMovies = writable({
  add: undefined,
  edit: undefined
})

export const nextMovie = derived(
  toWatchList,
  $toWatchList => $toWatchList.find(movie => movie.next === true)
)
