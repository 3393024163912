<script>
  import { fade } from 'svelte/transition'
  import { settings } from './store'
  import { getValueFromPath, settingsOptions } from './utils'

  import Grid from './views/Grid.svelte'
  import Table from './views/Table.svelte'

  export let movies
  export let title
  export let type
  export let colors

  let listGroups = {}
  let previousGroup

  const updateGroups = () => {
    const currentGroup = $settings[type].group

    if (!previousGroup || previousGroup !== currentGroup) {
      listGroups = {}
    } else {
      for (const groupName in listGroups) {
        listGroups[groupName].list = []
      }
    }

    for (const movie of movies) {
      const groupName = getValueFromPath(movie, settingsOptions[type].group[currentGroup])

      if (listGroups[groupName]) {
        listGroups[groupName].list.push(movie)
      } else {
        listGroups[groupName] = {
          collapse: $settings[type].collapse,
          list: [movie]
        }
      }
    }

    for (const groupName in listGroups) {
      if (listGroups[groupName].list.length === 0) {
        delete listGroups[groupName]
      }
    }

    previousGroup = currentGroup
  }

  const collapseAll = () => {
    for (const groupName in listGroups) {
      listGroups[groupName].collapse = $settings[type].collapse
    }
  }

  $: {
    (() => $settings[type])()
    collapseAll()
  }

  $: {
    (() => movies)()
    updateGroups()
  }
</script>

<section class='movie-gallery' transition:fade>
  <h2 class='list-title' style='color:{colors.text}; text-shadow:3px 3px 0 {colors.shadow}'>{title}</h2>

  {#if $settings[type].group !== 'none'}

    {#each Object.entries(listGroups) as [groupName, group]}
      <div class='list-group'>
        <button
          class='collapser'
          on:click={() => listGroups[groupName].collapse = !listGroups[groupName].collapse}
        >
          <h3 class='group-name'>
            {groupName}
            <span>
              [{listGroups[groupName].collapse ? `+${group.list.length}` : '-'}]
            </span>
          </h3>
        </button>
        {#if !listGroups[groupName].collapse}
          {#if $settings[type].view === 'grid'}
            <Grid movies={group.list}/>
          {:else}
            <Table
              movies={group.list}
              showPoster={$settings[type].posters}
              {type}
            />
          {/if}
        {/if}
      </div>
    {/each}

  {:else}

    {#if $settings[type].view === 'grid'}
      <Grid movies={movies}/>
    {:else}
      <Table
        movies={movies}
        showPoster={$settings[type].posters}
        {type}
      />
    {/if}

  {/if}
</section>

<style>
  section {
    margin: 40px 0;
  }

  .list-title {
    margin: 0 auto;
    width: 90%;
    font-family: Bebas Neue;
    font-size: 36px;
    display: flex;
    align-items: center;
    text-shadow: 3px 3px 0 #bdbdbd55;
  }

  .list-title::before,
  .list-title::after {
    content: '';
    height: 9px;
    background: #bdbdbd22;
    flex: 1;
    margin-bottom: 5px;
  }

  .list-title::before {
    margin-right: 10px;
  }

  .list-title::after {
    margin-left: 10px;
  }

  .list-group {
    margin: 20px 0;
  }

  .collapser {
    width: 90%;
    margin: 0 auto;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    color: #bdbdbd;
    display: block;
  }

  .collapser span {
    font-family: monospace;
    font-weight: inherit;
    font-size: 16px;
    text-decoration: none;
    margin-left: 10px;
    align-self: center;
    color: #bdbdbd4d;
  }

  .group-name {
    margin: 0 auto;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    font-family: Zilla Slab;
  }

  .group-name::after {
    content: '';
    height: 3px;
    background: #bdbdbd08;
    flex: 1;
    margin: 0 0 5px 10px;
  }
</style>
