<script>
  import { getPosterPath, getTruncatedOverview, getLanguage } from './utils.js'
  import { fly } from 'svelte/transition'
  import { selectedMovies } from './store'

  // API Fetch constants
  // const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'
  const TMDB_SEARCH = 'https://api.tmdb.org/3/search/movie?api_key=84106efd1bc6f76159ac03c384be440d&query='

  // Search object containing information about the search
  const search = {
    loading: false, // Is the current search loading?
    entered: false, // Has trimmed text been entered?
    found: true, // Has the movie been found?
    value: {
      raw: '', // raw input value
      trimmed: '' // trimmed input value to be used everywhere
    },
    results: {
      all: [], // all the results fetched
      active: [] // only the ones currently being shown
    },
    cache: {} // cached list
  }

  // reactively updated value.trimmed when value.raw changes
  $: search.value.trimmed = search.value.raw.trim()

  // to abort unneeded fetch queries
  let ongoingFetchController = new AbortController()

  // main search handler
  async function handleInputChange () {
    const currentSearchValue = search.value.trimmed

    if (currentSearchValue.length === 0) {
      search.entered = false
      search.results.all = []
      search.results.active = []
      return
    }

    search.entered = true

    if (currentSearchValue in search.cache) {
      ongoingFetchController.abort()
      search.results.all = search.cache[currentSearchValue]
      return
    }

    if (search.loading) {
      ongoingFetchController.abort()
    }

    search.loading = true
    ongoingFetchController = new AbortController()

    const query = encodeURIComponent(currentSearchValue)
    await fetch(TMDB_SEARCH + query, {
      signal: ongoingFetchController.signal
    })
      .then(async res => {
        const json = await res.json()
        search.found = json.results.length > 0
        search.results.all = search.cache[currentSearchValue] = json.results
        search.results.active = search.results.all.slice(0, 3)
      })
      .catch(() => console.log('Fetch aborted to load cache'))

    search.loading = false
  }

  // if a movie search result or custom result is clicked
  async function handleMovieClick (movie) {
    search.results.all = []
    search.results.active = []
    search.loading = false
    search.entered = false

    ongoingFetchController.abort()

    $selectedMovies.add = movie
  }
</script>

<div class='input-container'>
  <input
    class='search'
    type='text'
    autocomplete='off'
    id='add-movie-input'
    placeholder='Add a movie...'
    bind:value={search.value.raw}
    on:input={handleInputChange}
  >

  {#if search.entered}
    <div class='search-results'>
      {#if search.entered && !search.found && !search.loading}
        <p class='error'>No movie found :(</p>

      {:else}
        {#each search.results.active as movie}
          <div
            class='search-item'
            on:click={() => handleMovieClick(movie)}
            transition:fly={{ x: -200, duration: 300 }}
          >
            <img src={getPosterPath(movie)} alt='{movie.title} movie poster'/>
            <div class='info'>
              <h2>{movie.title}</h2>
              <p class='overview'>
                <span>{getLanguage(movie.original_language)}</span>
                {#if movie.release_date !== ''}
                  <span class='year'>&middot; {new Date(movie.release_date).getFullYear()}</span>
                {/if}
                {getTruncatedOverview(movie)}
              </p>
            </div>
          </div>
        {/each}

        {#if search.results.active.length < search.results.all.length}
          <div
            class='load-more'
            on:click={() => {
              search.results.active = search.results.all.slice(0, search.results.active.length + 3)
            }}
          >Load more...</div>
        {/if}
      {/if}

      <div class='custom-item' on:click={() => handleMovieClick({ title: search.value.trimmed, custom: true })}>
        <img src='/icons/plus.svg' alt=''>
        <p>Add custom</p>
      </div>
    </div>
  {/if}
</div>

<style>
  .input-container {
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 10px 20px rgba(0,0,0,0.12);
    border-radius: 5px;
    transition: 0.1s all linear;
  }

  .input-container:active,
  .input-container:focus-within,
  .input-container:focus,
  .input-container:hover {
    box-shadow: 0 20px 50px rgba(0,0,0,0.5);
  }

  .search {
    background: #1e1e30;
    width: 100%;
    font-family: Zilla Slab, serif;
    font-size: 26px;
    color: #6c757d;
    border-radius: 5px;
    border: none;
    padding: 20px;
    outline: none;
    font-weight: bold;
  }

  .error {
    font-size: 22px;
    color: #8a4041;
    padding: 10px 0;
    font-weight: 900;
    text-align: center;
    border-bottom: 1px solid #bdbdbd11;
  }

  .search-results {
    border: 1px solid #bdbdbd11;
    border-top: none;
    border-radius: 0 0 5px 5px;
  }

  .search-item {
    width: 100%;
    display: flex;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #bdbdbd11;
  }

  .search-item img {
    height: 100px;
    width: 70px;
    object-fit: cover;
    margin-right: 10px;
  }

  .search-item h2 {
    font-size: 22px;
    font-weight: 400;
  }

  .search-item .overview {
    font-size: 16px;
    line-height: 1.5;
    font-family: IBM Plex Sans, sans-serif;
    color: #888;
    margin-top: 5px;
  }

  .search-item .overview span {
    font-size: 14px;
    color: #777;
    font-style: italic;
  }

  .search-item .overview span:last-child {
    margin-right: 10px;
  }

  .load-more {
    font-weight: bold;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #bdbdbd11;
    cursor: pointer;
  }

  .custom-item {
    display: flex;
    width: 100%;
    padding: 0 10px;
    align-items: center;
  }

  .search-item:hover,
  .custom-item:hover,
  .load-more:hover {
    background: #131320;
  }

  .custom-item img {
    height: 20px;
    margin-right: 10px;
    margin-left: 5px;
  }

  .custom-item p {
    font-size: 16px;
    font-weight: bold;
    color: #888;
    cursor: pointer;
    padding: 15px 0;
    flex: 1;
  }
</style>
