<script>
  import { fly } from 'svelte/transition'

  import Search from './Search.svelte'
  import Next from './Next.svelte'
  import AddMovie from './forms/Add.svelte'
  import EditMovie from './forms/Edit.svelte'
  import Settings from './Settings.svelte'
  import List from './List.svelte'
  import Stats from './Stats.svelte'

  import { getAndSetMovieList, closeForm } from './utils'
  import {
    allMoviesList,
    toWatchList,
    watchedList,
    selectedMovies
  } from './store'

  // escape key handle
  function handleEscape (event) {
    if (event.keyCode === 27) {
      closeForm()
    }
  }
</script>

<!-- close the form on escape button -->
<svelte:window on:keydown={handleEscape}/>

<!-- section containging search bar and add form -->
<section id='search-add'>
  <Search/>

  {#if $selectedMovies.add}
    <AddMovie movie={$selectedMovies.add}/>
  {/if}
</section>

<!-- Next movie -->
<Next/>

<!-- settings to render the lists -->
<Settings/>

<!-- the lists of movies -->
<section id='lists'>
  {#await getAndSetMovieList()}
    <p class='loading' transition:fly={{ x: -100, duration: 200 }}>
      {$allMoviesList.length === 0 ? 'loading ' : 'updating '} movies...
    </p>
  {/await}

  {#if $allMoviesList.length > 0}
    <List
      title={'To Watch'}
      type={'toWatch'}
      movies={$toWatchList}
      colors={{
        text: '#c9a433',
        shadow: '#c9a43345'
      }}
    />

    <List
      title={'Watched'}
      type={'watched'}
      movies={$watchedList}
      colors={{
        text: '#4CAF50',
        shadow: '#4CAF5045'
      }}
    />
  {/if}
</section>

<!-- modal-like edit-movie form -->
{#if $selectedMovies.edit}
  <EditMovie movie={$selectedMovies.edit}/>
{/if}

<!-- watch statistcs -->
<Stats/>

<style>
  #search-add,
  #lists {
    margin: 40px 0;
  }

  .loading {
    font-size: 30px;
    margin: 40px auto;
    width: 90%;
    font-family: Bebas Neue;
  }
</style>
