<script>
  import dateformat from 'dateformat'
  import { summary } from 'date-streaks'
  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import { toWatchList, watchedList, settings } from './store'
  import { runtimeFormat } from './utils'

  function getMovieDatesMap () {
    const movieDates = {}
    for (const movie of $watchedList) {
      const date = dateformat(movie.watched.date, 'isoDate')
      if (movieDates[date]) {
        movieDates[date].list.push(movie)
        movieDates[date].watchtime += movie.info.runtime
      } else {
        movieDates[date] = {
          list: [movie],
          watchtime: movie.info.runtime
        }
      }
    }
    return movieDates
  }

  function putDate (dates, date, newMonth) {
    if (newMonth) {
      dates.push([[date]])
      return
    }

    const month = dates[dates.length - 1]
    if (month[month.length - 1].length < 7) {
      month[month.length - 1].push(date)
    } else {
      month.push([date])
    }
  }

  function dateList () {
    let dates
    const nextMilliseconds = 24 * 60 * 60 * 1000
    const today = new Date()
    // start from 3rd Feb 2020 (monday)
    let currentDate = new Date('2020-02-03T00:00:00').getTime()

    if ($settings.collapseMonths) {
      dates = []
      let currentWeek = []

      while (currentDate <= Date.now()) {
        const dateString = dateformat(currentDate, 'isoDate')
        if (currentWeek.length < 7) {
          currentWeek.push(dateString)
        } else {
          dates.push(currentWeek)
          currentWeek = [dateString]
        }
        currentDate += nextMilliseconds
      }

      dates.push(currentWeek)
    } else {
      dates = [[[]]]
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      while (currentDate <= nextMonth) {
        const dateString = dateformat(currentDate, 'isoDate')
        if (dateString.endsWith('01')) {
          const lastMonth = dates[dates.length - 1]
          for (let i = 0; i < lastMonth[lastMonth.length - 1].length; i++) {
            putDate(dates, null, i === 0)
          }
        }
        putDate(dates, dateString, false)
        currentDate += nextMilliseconds
      }
    }

    return dates
  }

  const todayIsoDate = dateformat(Date.now(), 'isoDate')

  function getDateCellColor (date) {
    if (heatMapType === 'count') {
      if (!date || date < '2020-02-06') {
        return 'transparent'
      } else if (date > todayIsoDate) {
        return '#171724'
      } else {
        const { list = [] } = (stats.movieDatesMap[date] || {})
        switch (list.length) {
          case 0: return '#303045'
          case 1: return '#315c3d'
          case 2: return '#4caf50'
        }
      }
    } else {
      if (!date || date < '2020-02-06') {
        return 'transparent'
      } else if (date > todayIsoDate) {
        return '#171724;'
      } else {
        const { watchtime = 0 } = (stats.movieDatesMap[date] || {})
        if (watchtime === 0) return '#303045'
        const { best, worst } = stats.watchtime
        return `rgba(76, 175, 80, ${0.15 + (watchtime - worst) / (best - worst) * 0.85})`
      }
    }
  }

  function getMonthString (week) {
    if (week[0] === '2020-02-03') return 'Feb'

    const firstDayOfMonth = week.find(day => day && day.endsWith('01'))
    if (firstDayOfMonth) {
      return dateformat(firstDayOfMonth, 'mmm')
    }

    return ''
  }

  let collapseStats = false
  let heatMapType = 'count'

  $: stats = (() => {
    const movieDatesMap = getMovieDatesMap()
    const watchtimeList = Object.values(movieDatesMap).map(date => date.watchtime)

    const watchtime = {
      total: $watchedList.reduce((a, b) => a + b.info.runtime, 0),
      best: Math.max(...watchtimeList),
      worst: Math.min(...watchtimeList)
    }

    const movieCount = {
      watched: $watchedList.length,
      toWatch: $toWatchList.length
    }

    return {
      watchtime,
      movieCount,
      movieDatesMap,
      streaks: summary(Object.keys(movieDatesMap)),
      dateList: dateList(),
      ratings: {
        combined: (
          $watchedList.reduce((a, b) => a + b.watched.ratings.mihir + b.watched.ratings.ananya, 0) / movieCount.watched
        ).toFixed(1),
        mihir: (
          $watchedList.reduce((a, b) => a + b.watched.ratings.mihir, 0) / movieCount.watched
        ).toFixed(1),
        ananya: (
          $watchedList.reduce((a, b) => a + b.watched.ratings.ananya, 0) / movieCount.watched
        ).toFixed(1),
        imdb: (
          $watchedList.reduce((a, b) => a + b.info.imdb.rating, 0) / movieCount.watched
        ).toFixed(1)
      }
    }
  })()

  let daysChartContainer, previousScrollWidth
  const scrollToRight = () => {
    const currentScrollWidth = daysChartContainer.scrollWidth
    if (!previousScrollWidth || currentScrollWidth !== previousScrollWidth) {
      previousScrollWidth = currentScrollWidth
      daysChartContainer.scrollLeft += currentScrollWidth
    }
  }

  onMount(() => {
    scrollToRight()
  })
</script>

<section id='stats'>
  <div class='title'>
    <button
      class='collapser'
      on:click={() => collapseStats = !collapseStats}
    >
      <h2>Stats</h2>
      <span>[{collapseStats ? '+' : '-'}]</span>
    </button>
  </div>

  {#if !collapseStats}
    <div class='container' transition:fade={{ duration: 200 }}>
      <div class='stats-group'>
        <h3>Watch time</h3>
        <ul>
          <li>
            <label>Time watched:</label>
            <span>{runtimeFormat(stats.watchtime.total)}</span>
          </li>
          <li>
            <label>That's about</label>
            <span>{(stats.watchtime.total / 1440).toFixed(1)} days</span>
          </li>
          <li>
            <label>Average movie runtime:</label>
            <span>{runtimeFormat(stats.watchtime.total / stats.movieCount.watched)}</span>
          </li>
        </ul>
      </div>

      <div class='stats-group'>
        <h3>Number of movies</h3>
        <ul>
          <li>
            <label>To watch:</label>
            <span>{stats.movieCount.toWatch}</span>
          </li>
          <li>
            <label>Watched:</label>
            <span>{stats.movieCount.watched}</span>
          </li>
        </ul>
      </div>

      <div class='stats-group'>
        <h3>Average ratings</h3>
        <ul>
          <li>
            <label>Combined:</label>
            <span>{stats.ratings.combined} / 10</span>
          </li>
          <ul class='list-level-2'>
            <li>
              <label>Ananya:</label>
              <span>{stats.ratings.ananya} / 5</span>
            </li>
            <li>
              <label>Mihir:</label>
              <span>{stats.ratings.mihir} / 5</span>
            </li>
          </ul>
          <li>
            <label>On IMDB:</label>
            <span>{stats.ratings.imdb} / 10</span>
          </li>
        </ul>
      </div>

      <div class='stats-group'>
        <h3>Number of days</h3>
        <ul>
          <li>
            <label>Current streak:</label>
            <span>{stats.streaks.currentStreak} days</span>
          </li>
          <li>
            <label>Longest streak:</label>
            <span>{stats.streaks.longestStreak} days</span>
          </li>
        </ul>
      </div>

      <div class='options'>
        <div class='input-group'>
          <input id='collapseMonths' type='checkbox' bind:checked={$settings.collapseMonths}>
          <label for='collapseMonths'>Collapse months</label>
        </div>

        <div class='input-group radio'>
          <label><input type='radio' bind:group={heatMapType} value='count'> Count</label>
          <label><input type='radio' bind:group={heatMapType} value='watchtime'> Watchtime</label>
        </div>
      </div>

      <div class='chart-container' bind:this={daysChartContainer}>
        <div class='days-chart' class:flexWrap={!$settings.collapseMonths}>
          <div class='week week-letters'>
            {#each (' MTWTFSS').split('') as day}
              <div class='cell day-name'>{day}</div>
            {/each}
          </div>

          {#if $settings.collapseMonths}
            {#each stats.dateList as week}
              <div class='week'>
                <div class='cell'>{getMonthString(week)}</div>
                {#each week as day}
                  <div
                    class='cell date-cell'
                    style='background: {getDateCellColor(day, heatMapType)}'
                    data-info='{dateformat(day, 'dd/mm/yyyy')}{
                      stats.movieDatesMap[day]
                      ? ': ' + stats.movieDatesMap[day].list.map(m => m.title).join(', ')
                      : ''
                    }'
                  ></div>
                {/each}
              </div>
            {/each}

          {:else}
            {#each stats.dateList as month}
              <div class='month'>
                {#each month as week, index}
                  <div class='week'>
                    <div class='cell month-name'>
                      {index === 1 ? dateformat(month[1][0], 'mmm') : ''}
                    </div>
                    {#each week as day}
                      <div
                        class='cell date-cell'
                        style='background: {getDateCellColor(day, heatMapType)}'
                        data-info='{dateformat(day, 'dd/mm/yyyy')}{
                          stats.movieDatesMap[day]
                          ? ': ' + stats.movieDatesMap[day].list.map(m => m.title).join(', ')
                          : ''
                        }'
                      ></div>
                    {/each}
                  </div>
                {/each}
              </div>
            {/each}
          {/if}

        </div>
      </div>
    </div>
  {/if}
</section>

<style>
  section {
    width: 90%;
    margin: 60px auto 40px auto;
    border-radius: 5px;
    background: #1e1e30;
    padding: 20px;
    border: 4px solid #193651;
    box-shadow: 0 10px 30px rgba(0,0,0,0.3);
  }

  .title {
    border-bottom: 1px solid #bdbdbd11;
    padding-bottom: 10px;
  }

  h2 {
    font-size: 32px;
    font-family: Bebas Neue;
    color: #0d70a4;
  }

  .collapser {
    font-family: monospace;
    text-decoration: none;
    font-size: 16px;
    align-items: center;
    color: #bdbdbd4d;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
  }

  .container {
    margin-top: 20px;
    margin-bottom: -20px;
  }

  .stats-group {
    margin: 20px 0;
  }

  h3 {
    font-size: 22px;
  }

  ul.list-level-2 {
    margin-left: 20px;
  }

  li {
    list-style-type: circle;
    list-style-position: inside;
    margin: 10px 0;
  }

  li label {
    font-family: IBM Plex Sans;
  }

  li span {
    font-size: 20px;
    font-weight: 700;
    margin: 0 5px;
  }

  .options {
    margin-top: 30px;
  }

  .input-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: IBM Plex Sans;
    margin: 10px 0;
  }

  .input-group label {
    margin-left: 7px;
    display: flex;
    align-items: center;
  }

  .input-group.radio label {
    margin-left: 0;
    margin-right: 10px;
  }

  .input-group.radio input {
    margin-right: 5px;
  }

  .chart-container {
    overflow-x: auto;
    margin-bottom: 20px;
    width: 100%;
  }

  .days-chart {
    padding: 30px 0 2px 0;
    display: flex;
  }

  .flexWrap {
    flex-wrap: wrap;
  }

  .month {
    display: flex;
    margin-right: 4px;
  }

  .week {
    margin: 5px 0;
  }

  .week-letters {
    left: 0;
    position: sticky;
    z-index: 1000;
    backdrop-filter: blur(4px);
    background: rgba(30, 30, 48, 0.75);
  }

  .cell {
    height: 14px;
  }

  .week div:first-child {
    height: 15px;
    width: 14px;
    font-size: 10px;
    font-family: IBM Plex Sans;
    color: #888;
  }

  .date-cell {
    position: relative;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #1e1e30;
  }

  .date-cell:not(.color--2):hover {
    outline: 2px solid #0d70a4;
    z-index: 10;
  }

  .date-cell:not(.color--2):hover::after,
  .date-cell:not(.color--2):focus::after {
    content: attr(data-info);
    display: block;
    position: absolute;
    top: -45px;
    padding: 5px;
    background: #161625;
    border-radius: 5px;
    border: 2px solid #686868;
    z-index: 100;
    white-space: nowrap;
    box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  }

  .day-name {
    width: 20px;
    display: flex;
    justify-content: center;
    font-size: 10px;
    font-family: IBM Plex Sans;
    color: #888;
  }
</style>
