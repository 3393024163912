<script>
  import { fade, slide } from 'svelte/transition'
  import { settings } from './store'
  import { titler, settingsOptions, getAndSetMovieList, sleep } from './utils'

  let showSettings = false

  const refresh = async event => {
    const btn = event.target

    btn.innerText = 'Refreshing...'
    await getAndSetMovieList()
    btn.innerText = 'Done!'

    await sleep(1000)
    btn.innerText = 'Refresh'
  }

  const clear = async event => {
    const btn = event.target

    localStorage.clear()
    btn.innerText = 'Done!'

    await sleep(1000)
    btn.innerText = 'Clear'
  }
</script>

<section id='settings'>
  <div class='main-btns'>
    <div>
      <button
        class='{showSettings ? 'open' : ''}'
        on:click={() => showSettings = !showSettings}
      >Settings</button>
    </div>
    <button on:click={refresh}>Refresh</button>
    <button on:click={clear}>Clear</button>
  </div>
  {#if showSettings}
    <div class='settings' transition:slide={{ duration: 200 }}>
      <input bind:value={$settings.fuzzyFilter} class='fuzzy-filter' placeholder='Filter movies by title'>

      <div class='list-groups'>
        {#each [
          { name: 'To Watch', key: 'toWatch' },
          { name: 'Watched', key: 'watched' }
        ] as {name: listName, key: listKey}}
          <div class='list-group'>
            <h3>{listName}</h3>

            <div class='settings-group'>
              <label>View:</label>
              <div class='buttons'>
                {#each ['grid', 'table'] as view}
                  <button
                    class={$settings[listKey].view === view ? 'active' : ''}
                    on:click={() => $settings[listKey].view = view}
                  >{view}</button>
                {/each}
              </div>
            </div>

            {#if $settings[listKey].view === 'table'}
              <div class='settings-group'>
                <label for='show-posters-{listKey}'>Show posters:</label>
                <input type='checkbox' id='show-posters-{listKey}' bind:checked={$settings[listKey].posters}>
              </div>
            {/if}

            <div class='settings-group'>
              <label>Group:</label>
              <select bind:value={$settings[listKey].group}>
                {#each Object.keys(settingsOptions[listKey].group) as option}
                  <option value={option}>{titler(option)}</option>
                {/each}
              </select>
            </div>

            <div class='settings-group'>
              <label>Sort:</label>
              <select bind:value={$settings[listKey].sort}>
                {#each settingsOptions[listKey].sort as option}
                  <option value={option}>{titler(option)}</option>
                {/each}
              </select>
            </div>

            <div class='settings-group'>
              <label for='collapse-{listKey}'>Collapse groups:</label>
              <input type='checkbox' id='collapse-{listKey}' bind:checked={$settings[listKey].collapse}>
            </div>

            <div class='settings-group'>
              <label for='reverse-{listKey}'>Reverse:</label>
              <input type='checkbox' id='reverse-{listKey}' bind:checked={$settings[listKey].reverse}>
            </div>
          </div>
        {/each}
      </div>
    </div>
  {/if}
</section>

<style>
  section {
    width: 90%;
    margin: 40px auto;
  }

  .main-btns {
    display: flex;
  }

  .main-btns button {
    border-radius: 5px;
    background: #1e1e30;
    padding: 10px;
    border: none;
    outline: none;
    font-family: Bebas Neue;
    font-size: 16px;
    color: #bdbdbd;
    cursor: pointer;
    transition: 0.1s all linear;
    height: fit-content;
    margin-left: 10px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.3);
  }

  .main-btns button:not(.open):hover,
  .main-btns button:not(.open):active {
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
    background: #1e1e3011;
  }

  .main-btns > div {
    flex: 1;
  }

  .main-btns > div button {
    margin-left: 0 !important;
  }

  .main-btns button.open {
    border-radius: 5px 5px 0 0;
    padding-bottom: 20px;
    overflow: hidden;
    box-shadow: none;
  }

  .settings {
    border-radius: 0 5px 5px 5px;
    background: #1e1e30;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
    z-index: 1;
  }

  input.fuzzy-filter {
    background: #161625;
    padding: 5px;
    font-size: 16px;
    font-family: IBM Plex Sans;
    color: #bbb;
    margin: 5px 2.5% 15px;
    border: none;
    border-radius: 5px;
  }

  input.fuzzy-filter:focus,
  input.fuzzy-filter:active {
    outline-color: #72717a;
  }

  .list-groups {
    display: flex;
    justify-content: space-around;
    padding: 5px 0 10px 0;
  }

  .list-group {
    width: 42.5%;
  }

  h3 {
    font-family: Bebas Neue;
  }

  .settings-group {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .settings-group label {
    font-family: IBM Plex Sans;
    margin-right: 10px;
    font-size: 14px;
    flex: 1;
  }

  .buttons button,
  .settings-group select {
    border-radius: 5px;
    font-size: 16px;
    font-family: Bebas Neue;
    cursor: pointer;
    outline: none;
  }

  .buttons button {
    color: #72717a;
    padding: 2px 5px;
    background: #1e1e30;
    border: 3px solid #1e1e30;
    transition: 0.1s all linear;
  }

  .buttons button.active {
    border-color: #3e3e4c;
    background: #3e3e4c;
    color: #bdbdbd;
  }

  select {
    padding: 5px 5px;
    color: #bdbdbd;
    border: none;
    background: #3e3e4c;
  }
</style>
