<script>
  import dateformat from 'dateformat'
  import { fade } from 'svelte/transition'

  import Switch from '../Switch.svelte'
  import { selectedMovies } from '../store'
  import {
    getAndSetMovieList,
    getPosterPath,
    setValueToPath,
    getLanguage,
    closeForm,
    sleep
  } from '../utils'

  export let movie

  // api constants
  const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'
  const ADD_MOVIE_ENDPOINT = 'https://movies.mihir.ch/api/add'
  const TMBD_MOVIE = id => `https://api.tmdb.org/3/movie/${id}?api_key=84106efd1bc6f76159ac03c384be440d`
  const IMDB_MOVIE = id => `https://www.imdb.com/title/${id}/`

  // have we watched the movie?
  let isMovieWatched = false
  let submitButtonText = 'Add!'
  let editInfo = false
  let expandOverview = false

  const ratings = {
    ananya: 0,
    mihir: 0
  }

  // now date formatted
  const nowDateFormatted = dateformat(new Date(), 'isoDate')

  // get all movie info
  async function getMovieInfo (movie) {
    if (movie.custom) {
      return movie
    }

    const info = await fetch(TMBD_MOVIE(movie.id)).then(res => res.json())
    return info
  }

  // parse imdb rating of movie
  async function getImdbRating (id) {
    if (!id) return -1

    const html = await fetch(CORS_PROXY + IMDB_MOVIE(id)).then(res => res.text())

    const tempEl = document.createElement('div')
    tempEl.innerHTML = html

    const ratingEl =
      tempEl.querySelector('span[itemprop="ratingValue"]') ||
      tempEl.querySelector('#ratings-bar > div:nth-child(1) > span.inline-block.text-left.vertically-middle')

    if (!ratingEl) return -1
    return Number(ratingEl.innerText.split('/')[0])
  }

  // finally add the movie to the database
  async function addMovie (event) {
    const form = event.target

    const formData = new FormData(form)
    const checkboxes = [...document.querySelectorAll('.add-movie input[type="checkbox"]')]
    checkboxes.map(cb => formData.set(cb.name, cb.checked))

    const requestBody = {}
    for (const [path, value] of formData.entries()) {
      setValueToPath(requestBody, path, value)
    }

    submitButtonText = 'Getting IMDb rating...'
    requestBody.info.imdb.rating = await getImdbRating(requestBody.info.imdb.id)

    submitButtonText = 'Adding...'
    await fetch(ADD_MOVIE_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(requestBody)
    })

    submitButtonText = 'Updating list...'
    await getAndSetMovieList()

    submitButtonText = 'Done!'

    await sleep(250)

    /* eslint-disable eqeqeq */
    if ($selectedMovies.add.id == requestBody.info.tmdbId) {
      closeForm('add')
    } else {
      submitButtonText = 'Add!'
    }
  }
</script>

<div class='add-movie' transition:fade>
  <h2>Add Movie</h2>

  {#await getMovieInfo(movie)}
    <p class='loading'>Loading movie data...</p>

  {:then movie}
    <form on:submit|preventDefault={addMovie} transition:fade>
      <input type='hidden' name='info.tmdbId' value={movie.id || Date.now()}>
      <input type='hidden' name='info.poster' value={movie.poster_path}>
      <input type='hidden' name='info.runtime' value={movie.runtime}>
      <input type='hidden' name='info.overview' value={movie.overview}>
      <input type='hidden' name='info.imdb.id' value={movie.imdb_id || Date.now()}>

      <div class='info'>
        <img src={getPosterPath(movie)} alt='{movie.title} movie poster'/>

        <div class='inputs'>
          <input
            type='text'
            name='title'
            value={movie.title || ''}
            readonly={!editInfo}
          />
          <div class='input-group'>
            <label>Language:</label>
            <input
              type='text'
              name='info.language'
              value={getLanguage(movie.original_language) || ''}
              readonly={!editInfo}
              placeholder='Language'
            />
          </div>
          <div class='input-group'>
            <label>Runtime (mins):</label>
            <input
              type='number'
              name='info.runtime'
              value={movie.runtime || 0}
              readonly={!editInfo}
            />
          </div>
          {#if movie.overview.length < 120}
            <p class='overview'>{movie.overview}</p>
          {:else}
            <p class='overview' on:click={() => expandOverview = true}>{
              expandOverview ? movie.overview : movie.overview.substr(0, 100) + '... [+]'
            }</p>
          {/if}
          {#if !editInfo}
            <button type='button' class='edit' on:click={() => editInfo = true}>Edit this</button>
          {/if}
        </div>
      </div>

      <div class='bottom'>
        <div class='input-group'>
          <label>Added by:</label>
          <select name='adder'>
            <option value='Ananya'>Ananya</option>
            <option value='Mihir'>Mihir</option>
            <option value='Both'>Both</option>
          </select>
        </div>

        <div class='input-group'>
          <label>Date added:</label>
          <input type='date' name='added' value={nowDateFormatted}>
        </div>

        <div class='input-group'>
          <label for='mark-next-cb'>Mark as next:</label>
          <input type='checkbox' name='next' id='mark-next-cb'>
        </div>

        <div class='input-group watched'>
          <label for='watched-cb'>Watched:</label>
          <Switch name={'watched.isWatched'} id={'watched-cb'} bind:checked={isMovieWatched}/>
        </div>

        {#if isMovieWatched}
          <div class='watched-section' transition:fade={{ duration: 100 }}>
            <div class='input-group'>
              <label>Date watched:</label>
              <input type='date' name='watched.date' value={nowDateFormatted}>
            </div>

            <div class='input-group'>
              <label>How:</label>
              <select name='watched.medium'>
                <option value='Torrent'>Torrent</option>
                <option value='Netflix'>Netflix</option>
                <option value='Amazon Prime'>Amazon Prime</option>
              </select>
            </div>

            <div class='input-group'>
              <label>When:</label>
              <select name='watched.when'>
                <option value='Quarantine'>Quarantine</option>
                <option value='Weekend'>Weekend</option>
                <option value='College'>College</option>
              </select>
            </div>

            <div class='input-group'>
              <label for='favourite-cb'>Favourite:</label>
              <input type='checkbox' name='watched.favourite' id='favourite-cb'>
            </div>

            <div class='ratings'>
              <h4>Ratings:</h4>

              <div class='input-group'>
                <label>Ananya:</label>
                <input
                  type='range' min='0' max='5' step='0.25'
                  name='watched.ratings.ananya'
                  bind:value={ratings.ananya}
                >
                <span>{ratings.ananya}/5</span>
              </div>

              <div class='input-group'>
                <label>Mihir:</label>
                <input
                  type='range' min='0' max='5' step='0.25'
                  name='watched.ratings.mihir'
                  bind:value={ratings.mihir}
                >
                <span>{ratings.mihir}/5</span>
              </div>
            </div>
          </div>
        {/if}
      </div>

      <div class='button-group'>
        <button type='button' on:click={() => closeForm('add')}>Cancel</button>
        <button type='submit'>{submitButtonText}</button>
      </div>
    </form>
  {/await}
</div>

<style>
  .loading {
    font-size: 18px;
    padding: 10px;
    text-align: center;
    font-family: IBM Plex Sans;
  }

  .add-movie {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 40px;
    border-radius: 5px;
    border: 4px solid #203733;
    background: #1e1e30;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  }

  .add-movie h2 {
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-family: Bebas Neue;
    font-size: 30px;
    border-bottom: 1px solid #bdbdbd11;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: IBM Plex Sans;
  }

    .info {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #bdbdbd11;
  }

  .info img {
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  }

  .info .inputs {
    flex: 1;
    width: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .info .inputs button.edit {
    flex: 1;
    border: none;
    background: none;
    font-family: IBM Plex Sans;
    outline: none;
    text-decoration: underline;
    color: #888;
    margin-top: 10px;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
  }

  .info .input-group label {
    width: auto;
    font-size: 14px;
    margin-right: 10px;
  }

  .info .input-group input {
    max-width: 70px;
  }

  .info input {
    font-family: IBM Plex Sans;
    background: none;
    border-radius: 5px;
    color: #bdbdbd;
  }

  .info input:not([readonly]) {
    padding: 5px;
    border: 1px solid#ffffff20;
  }

  .info input[readonly] {
    padding: 0;
    border: none;
  }

  .info input[name='title'] {
    font-size: 25px;
    font-family: Zilla Slab;
    margin-bottom: 5px;
    width: 100%;
  }

  .info input[readonly] {
    font-weight: bold;
  }

  .info input[name='title']:not([readonly]) {
    padding: 10px;
  }

  .info .overview {
    font-family: IBM Plex Sans;
    color: #6a6a73;
    font-size: 14px;
    margin-top: 10px;
  }

  .bottom input:not([type='checkbox']),
  .bottom select {
    padding: 5px;
    font-family: IBM Plex Sans;
    background: none;
    color: #bdbdbd;
    border: 1px solid #ffffff20;
    border-radius: 5px;
  }

  input[type='date'] {
    width: 150px;
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .button-group button {
    font-size: 18px;
    font-weight: 700;
    font-family: Bebas Neue;
    padding: 5px 10px;
    cursor: pointer;
    align-self: flex-end;
    border: none;
    color: #bdbdbd;
    transition: 0.1s all linear;
    margin-top: 10px;
    border-radius: 5px;
  }

  .button-group button[type='button'] {
    background: #354756;
    margin-right: 20px;
  }

  .button-group button[type='submit'] {
    background: #30583d;
  }

  .button-group button[type='submit']:hover {
    box-shadow: 0 0 30px #161625;
  }

  input:focus,
  input:active,
  select:focus,
  select:active {
    outline: none;
    border-color: #473f27;
  }

  .input-group {
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  label {
    margin-right: 10px;
    width: 120px;
  }

  .watched {
    margin-top: 20px;
  }

  .watched label {
    font-weight: 700;
  }

  .watched-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #bdbdbd11;
  }

  .watched-section .input-group {
    margin: 0 0 10px 0;
  }

  .ratings .input-group {
    margin-left: 10px;
  }

  .ratings h4 {
    color: #ddd;
    font-size: 16px;
    margin: 20px 0 10px 0;
  }

  .ratings label {
    width: 80px;
  }

  .ratings span {
    margin-left: 20px;
    font-family: monospace;
  }

  .ratings input {
    padding: 0 !important;
    margin: 5px 0;
  }
</style>
