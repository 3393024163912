<script>
  import { fade } from 'svelte/transition'
  import { selectedMovies } from '../store'
  import { getPosterPath } from '../utils'

  export let movies
</script>

<ul transition:fade={{ duration: 200 }}>
  {#each movies as movie}
    <li
      on:click={() => $selectedMovies.edit = movie}
      transition:fade={{ duration: 200 }}
    >
      <img src={getPosterPath(movie, true)} alt=''>
      <p>{movie.title}</p>
    </li>
  {/each}
</ul>

<style>
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto;
    width: calc(90% + 20px);
  }

  li {
    list-style-type: none;
    background: #333;
    margin: 10px;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
  }

  li p {
    background: rgba(0, 0, 0, 0.65);
    padding: 20px;
    font-size: 18px;
    font-weight: 500;
    color: white;
    z-index: 1;
    font-family: Bebas Neue;
  }

  img {
    width: 110%;
    position: absolute;
    align-self: center;
    filter: blur(2px);
  }
</style>
