<script>
  import dateformat from 'dateformat'
  import { fade, slide } from 'svelte/transition'

  import Switch from '../Switch.svelte'
  import { selectedMovies } from '../store'
  import {
    getAndSetMovieList,
    getPosterPath,
    setValueToPath,
    closeForm,
    sleep
  } from '../utils'

  export let movie

  const EDIT_MOVIE_ENDPOINT = 'https://movies.mihir.ch/api/edit'
  const DELETE_MOVIE_ENDPOINT = 'https://movies.mihir.ch/api/delete?id='

  movie.added = dateformat(movie.added, 'isoDate')
  movie.watched.date = dateformat(movie.watched.date || nowDateFormatted(), 'isoDate')

  const buttonsText = {
    save: 'Save',
    delete: 'Delete'
  }

  // Edit movie meta info
  let editInfo = false
  let expandOverview = false

  // now date formatted
  function nowDateFormatted () {
    const now = new Date()
    if (now.getHours() < 5) {
      now.setDate(now.getDate() - 1)
    }
    return now
  }

  async function editMovie (event) {
    const form = event.target
    buttonsText.save = 'Saving...'

    const formData = new FormData(form)
    const checkboxes = [...document.querySelectorAll('#edit-movie input[type="checkbox"]')]
    checkboxes.map(cb => formData.set(cb.name, cb.checked))

    const requestBody = { _id: movie._id }
    for (const [path, value] of formData.entries()) {
      setValueToPath(requestBody, path, value)
    }

    await fetch(EDIT_MOVIE_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(requestBody)
    })

    buttonsText.save = 'Updating list...'
    await getAndSetMovieList()

    buttonsText.save = 'Done!'
    await sleep(250)

    /* eslint-disable eqeqeq */
    if ($selectedMovies.edit._id == requestBody._id) {
      closeForm('edit')
    }
  }

  async function deleteMovie (movie) {
    buttonsText.delete = 'Deleting...'

    await fetch(DELETE_MOVIE_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(movie)
    })

    buttonsText.delete = 'Updating list...'
    await getAndSetMovieList()

    buttonsText.delete = 'Done!'
    await sleep(250)

    /* eslint-disable eqeqeq */
    if ($selectedMovies.edit._id == movie._id) {
      closeForm('edit')
    }
  }
</script>

<svelte:head>
  <style>
    body {
      overflow-y: hidden;
    }
  </style>
</svelte:head>

<section
  id='edit-movie'
  on:click={() => closeForm('edit')}
  transition:slide={{ duration: 200 }}
>
  <div class='container' on:click|stopPropagation>
    <h2>Edit Movie</h2>

    <form on:submit|preventDefault={editMovie} transition:fade>
      <input type='hidden' name='info.tmdbId' value={movie.info.tmdbId}>
      <input type='hidden' name='info.poster' value={movie.info.poster}>
      <input type='hidden' name='info.overview' value={movie.info.overview}>
      <input type='hidden' name='info.imdb.id' value={movie.info.imdb.id}>
      <input type='hidden' name='info.imdb.rating' value={movie.info.imdb.rating}>

      <div class='info'>
        <img src={getPosterPath(movie, true)} alt='{movie.title} poster'>

        <div class='inputs'>
          <input
            type='text'
            name='title'
            bind:value={movie.title}
            readonly={!editInfo}
          />
          <div class='input-group'>
            <label>Language:</label>
            <input
              type='text'
              name='info.language'
              bind:value={movie.info.language}
              readonly={!editInfo}
              placeholder='Language'
            />
          </div>
          <div class='input-group'>
            <label>Runtime (mins):</label>
            <input
              type='number'
              name='info.runtime'
              bind:value={movie.info.runtime}
              readonly={!editInfo}
            />
          </div>
          {#if movie.info.overview.length < 120}
            <p class='overview'>{movie.info.overview}</p>
          {:else}
            <p class='overview' on:click={() => expandOverview = true}>{
              expandOverview ? movie.info.overview : movie.info.overview.substr(0, 100) + '... [+]'
            }</p>
          {/if}
          {#if !editInfo}
            <button type='button' class='edit' on:click={() => editInfo = true}>Edit this</button>
          {/if}
        </div>
      </div>

      <div class='bottom'>
        <div class='input-group'>
          <label>Added by:</label>
          <select name='adder' bind:value={movie.adder}>
            <option value='Ananya'>Ananya</option>
            <option value='Mihir'>Mihir</option>
            <option value='Both'>Both</option>
          </select>
        </div>

        <div class='input-group'>
          <label>Date added:</label>
          <input type='date' name='added' bind:value={movie.added}>
        </div>

        <div class='input-group'>
          <label for='mark-next-cb'>Mark as next:</label>
          <input type='checkbox' name='next' id='mark-next-cb' bind:checked={movie.next}>
        </div>

        <div class='input-group watched'>
          <label for='watched-cb'>Watched:</label>
          <Switch name={'watched.isWatched'} id={'watched-cb'} bind:checked={movie.watched.isWatched}/>
        </div>

        {#if movie.watched.isWatched}
          <div class='watched-section' transition:slide={{ duration: 200 }}>
            <div class='input-group'>
              <label>Date watched:</label>
              <input type='date' name='watched.date' bind:value={movie.watched.date}>
            </div>

            <div class='input-group'>
              <label>How:</label>
              <select name='watched.medium' bind:value={movie.watched.medium}>
                <option value='Torrent'>Torrent</option>
                <option value='Netflix'>Netflix</option>
                <option value='Amazon Prime'>Amazon Prime</option>
              </select>
            </div>

            <div class='input-group'>
              <label>When:</label>
              <select name='watched.when' bind:value={movie.watched.when}>
                <option value='Quarantine'>Quarantine</option>
                <option value='Weekend'>Weekend</option>
                <option value='College'>College</option>
              </select>
            </div>

            <div class='input-group'>
              <label for='favourite-cb'>Favourite:</label>
              <input type='checkbox' name='watched.favourite' id='favourite-cb' bind:checked={movie.watched.favourite}>
            </div>

            <div class='ratings'>
              <h4>Ratings:</h4>

              <div class='input-group'>
                <label>Ananya:</label>
                <input
                  type='range' min='0' max='5' step='0.25'
                  name='watched.ratings.ananya'
                  bind:value={movie.watched.ratings.ananya}
                >
                <span>{movie.watched.ratings.ananya}/5</span>
              </div>

              <div class='input-group'>
                <label>Mihir:</label>
                <input
                  type='range' min='0' max='5' step='0.25'
                  name='watched.ratings.mihir'
                  bind:value={movie.watched.ratings.mihir}
                >
                <span>{movie.watched.ratings.mihir}/5</span>
              </div>
            </div>
          </div>
        {/if}

        <div class='button-group'>
          <button type='button' class='delete' on:click={() => deleteMovie(movie)}>{buttonsText.delete}</button>
          <div>
            <button type='button' class='cancel' on:click={() => closeForm('edit')}>Cancel</button>
            <button class='save'>{buttonsText.save}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>

<style>
  #edit-movie {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0,0,0,0.7);
    backdrop-filter: blur(4px);
  }

  .container {
    width: 90%;
    max-height: 90vh;
    max-width: 500px;
    overflow-y: auto;
    padding: 20px;
    border-radius: 5px;
    background: #161625;
    border: 4px solid #473f27;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
  }

  h2 {
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-family: Bebas Neue;
    font-size: 30px;
    border-bottom: 1px solid #bdbdbd11;
  }

  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: IBM Plex Sans;
  }

  .info {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #bdbdbd11;
  }

  .info img {
    height: 200px;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  }

  .info .inputs {
    flex: 1;
    width: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
  }

  .info .inputs button.edit {
    flex: 1;
    border: none;
    background: none;
    font-family: IBM Plex Sans;
    outline: none;
    text-decoration: underline;
    color: #888;
    margin-top: 10px;
    font-size: 14px;
    width: fit-content;
    cursor: pointer;
  }

  .info .input-group label {
    width: auto;
    font-size: 14px;
    margin-right: 10px;
  }

  .info .input-group input {
    max-width: 70px;
  }

  .info input {
    font-family: IBM Plex Sans;
    background: none;
    border-radius: 5px;
    color: #bdbdbd;
  }

  .info input:not([readonly]) {
    padding: 5px;
    border: 1px solid#ffffff20;
  }

  .info input[readonly] {
    padding: 0;
    border: none;
  }

  .info input[name='title'] {
    font-size: 25px;
    font-family: Zilla Slab;
    margin-bottom: 5px;
    width: 100%;
  }

  .info input[readonly] {
    font-weight: bold;
  }

  .info input[name='title']:not([readonly]) {
    padding: 10px;
  }

  .info .overview {
    font-family: IBM Plex Sans;
    color: #6a6a73;
    font-size: 14px;
    margin-top: 10px;
  }

  .bottom input:not([type='checkbox']),
  .bottom select {
    padding: 5px;
    font-family: IBM Plex Sans;
    background: none;
    color: #bdbdbd;
    border: 1px solid #ffffff20;
    border-radius: 5px;
  }

  input[type='date'] {
    width: 150px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .button-group button {
    font-size: 18px;
    font-weight: 700;
    font-family: Bebas Neue;
    padding: 5px 10px;
    cursor: pointer;
    align-self: flex-end;
    border: none;
    color: #bdbdbd;
    transition: 0.1s all linear;
    margin-top: 10px;
    border-radius: 5px;
  }

  button.delete {
    background: #5a2d2d;
  }

  button.cancel {
    background: #354756;
    margin-right: 20px;
  }

  button.save {
    background: #30583d;
  }

  button.save:hover {
    box-shadow: 0 0 30px #161625;
  }

  input:focus,
  input:active,
  select:focus,
  select:active {
    outline: none;
    border-color: #473f27;
  }

  .input-group {
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  label {
    margin-right: 10px;
    width: 120px;
  }

  .watched {
    margin-top: 20px;
  }

  .watched label {
    font-weight: 700;
  }

  .watched-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #1f1f2d;
  }

  .watched-section .input-group {
    margin: 0 0 10px 0;
  }

  .ratings .input-group {
    margin-left: 10px;
  }

  .ratings h4 {
    color: #ddd;
    font-size: 16px;
    margin: 20px 0 10px 0;
  }

  .ratings label {
    width: 80px;
  }

  .ratings span {
    margin-left: 20px;
    font-family: monospace;
  }

  .ratings input {
    padding: 0 !important;
    margin: 5px 0;
  }
</style>
