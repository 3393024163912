<script>
  import dateformat from 'dateformat'
  import { fade } from 'svelte/transition'
  import { getPosterPath, runtimeFormat, titler } from '../utils'
  import { selectedMovies, settings } from '../store'

  export let movies
  export let type
  export let showPoster

  let previousSortMethod

  function tableHeaderSort (event) {
    const currentSortMethod = event.target.dataset.sort

    if (currentSortMethod === previousSortMethod) {
      $settings[type].reverse = !$settings[type].reverse
    } else {
      $settings[type].reverse = false
    }

    $settings[type].sort = currentSortMethod
    previousSortMethod = currentSortMethod
  }
</script>

{#if type === 'toWatch'}

<div class='table-container' transition:fade={{ duration: 200 }}>
  <table>
    <thead>
      <tr>
        {#if showPoster}
          <th class='poster'></th>
        {/if}
        {#each [
          'alphabetical',
          'runtime',
          'added-by',
          'IMDb',
          'language',
          'date-added'
        ] as header}
          <th on:click={tableHeaderSort}
            data-sort={header}
            data-arrow={
              $settings[type].sort === header
              ? $settings[type].reverse ? '▼' : '▲'
              : ''
            }
          >{header === 'alphabetical' ? 'Movie' : titler(header)}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each movies as movie}
        <tr on:click={() => $selectedMovies.edit = movie}>
          {#if showPoster}
            <td class='poster'><img src={getPosterPath(movie, true)} alt='{movie.title} poster'></td>
          {/if}
          <td class='movie-title'>{movie.title}</td>
          <td>{runtimeFormat(movie.info.runtime)}</td>
          <td>{movie.adder}</td>
          <td>{movie.info.imdb.rating}</td>
          <td>{movie.info.language}</td>
          <td>{dateformat(movie.added, 'mmm d, yyyy')}</td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

{:else}

<div class='table-container' transition:fade={{ duration: 200 }}>
  <table>
    <thead>
      <tr>
        {#if showPoster}
          <th class='poster'></th>
        {/if}
        {#each [
          'alphabetical',
          'date-watched',
          'runtime',
          'ratings',
          'IMDb',
          'language',
          'added-by',
          'medium',
          'when'
        ] as header}
          <th on:click={tableHeaderSort}
            data-sort={header}
            data-arrow={
              $settings[type].sort === header
              ? $settings[type].reverse ? '▼' : '▲'
              : ''
            }
          >{header === 'alphabetical' ? 'Movie' : titler(header)}</th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each movies as movie}
        <tr class={movie.watched.favourite ? 'fave' : ''} on:click={() => $selectedMovies.edit = movie}>
          {#if showPoster}
            <td class='poster'><img src={getPosterPath(movie, true)} alt='{movie.title} poster'></td>
          {/if}
          <td class='movie-title'>{movie.title}</td>
          <td>{dateformat(movie.watched.date, 'mmm d, yyyy')}</td>
          <td>{runtimeFormat(movie.info.runtime)}</td>
          <td>{movie.watched.ratings.ananya + movie.watched.ratings.mihir}</td>
          <td>{movie.info.imdb.rating}</td>
          <td>{movie.info.language}</td>
          <td>{movie.adder}</td>
          <td>{movie.watched.medium}</td>
          <td>{movie.watched.when}</td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

{/if}

<style>
  .table-container {
    overflow: auto;
    margin: 0;
    padding-left: 5%;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.25);
  }

  table {
    align-self: center;
    border-collapse: separate;
    border-spacing: 3px 2px;
    font-family: IBM Plex Sans;
    position: relative;
    text-transform: none;
    margin-top: 40px;
    margin-bottom: 40px;
    box-shadow: 0 0 30px #00000070;
    border-radius: 10px;
  }

  table::after {
    content: '';
    width: 40px;
    height: 1px;
    display: block;
    position: absolute;
    right: -40px;
  }

  thead {
    background: #6c757d10;
    color: #bdbdbd;
    font-size: 16px;
    font-family: Zilla Slab;
  }

  thead th {
    border-radius: 5px;
    padding: 8px 15px;
    background: #1e1e30;
    border: 1px solid #bdbdbd1a;
    cursor: pointer;
  }

  thead th::after {
    content: attr(data-arrow);
    position: absolute;
    font-size: 10px;
    margin: 4px 0 0 3px;
    color: #77777f;
  }

  thead th:active,
  thead th:hover {
    background: #00000061 !important;
  }

  thead .poster {
    background: #161625;
    border: none;
  }

  tbody {
    color: #7c848b;
    max-width: 150px;
  }

  tbody tr {
    cursor: pointer;
  }

  tbody tr:nth-child(2n) {
    background: #1e1e30;
  }

  tbody tr.fave {
    background: #1c282b;
  }

  tbody tr:active,
  tbody tr:hover {
    background: #00000061 !important;
  }

  tbody td {
    border-radius: 5px;
    font-size: 15px;
    padding: 5px 10px;
  }

  tbody td.movie-title {
    font-weight: 700;
    width: 100%;
  }

  tbody td.poster {
    padding: 5px;
    display: flex;
  }

  tbody td img {
    width: 40px;
  }

  th, td {
    white-space: nowrap;
  }
</style>
